import request from '@/request'

// 导航菜单
const nav = () => {
  return request({
    url: '/index/navCategory',
    method: 'GET',
  })
}

// 首页标签
const indexLabel = () => {
  return request({
    url: '/Label/index',
    method: 'GET',
  })
}

// 案例标签
const caseLabel = () => {
  return request({
    url: '/index/category',
    method: 'GET',
  })
}

// 案例列表
const caseList = (params) => {
  return request({
    url: '/index/work',
    method: 'GET',
    params,
  })
}

// 案例详情
const caseDetail = (params) => {
  return request({
    url: '/index/details',
    method: 'POST',
    data: params,
  })
}

// 资讯列表
const infoList = (params) => {
  return request({
    url: '/industry/list',
    method: 'POST',
    data: params,
  })
}

// 资讯详情
const infoDetail = (params) => {
  return request({
    url: '/industry/details',
    method: 'POST',
    data: params,
  })
}

export {
  nav,
  indexLabel,
  caseLabel,
  caseList,
  caseDetail,
  infoList,
  infoDetail,
}

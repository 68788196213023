<template>
  <div class="home">
    <div class="banner">
      <img src="../../images/home-banner.webp" />
    </div>
    <div class="tabs">
      <div class="header">
        <ul>
          <li
            v-for="(item, index) in tabs"
            :key="index"
            :class="{ active: item.name === activeName }"
            @click="onTabClick(index)"
          >
            {{ item.label }}
          </li>
        </ul>
      </div>
      <div v-loading="loading">
        <ul class="labels" v-if="activeName === 'more'">
          <li
            class="label"
            v-for="(item, index) in labels"
            :key="item.label_id"
            @click="onLabelClick(index)"
          >
            <span :class="{ active: item.label_id === activeLabel }">{{
              item.label_name
            }}</span>
          </li>
        </ul>
        <div class="content center">
          <div class="caseBox">
            <div
              class="case center"
              v-for="item in caseList"
              :key="item.works_id"
            >
              <m-case :item="item" />
            </div>
          </div>
        </div>
        <div class="showMore">
          <div class="btn" v-if="hasData" @click="showMore">查看更多</div>
          <div v-else class="noMore">
            <span class="noMoreText">我是有底线的</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mCase from '@/components/mCase'
import { indexLabel, caseList } from '@/api/common'
export default {
  components: {
    mCase,
  },
  data() {
    return {
      navId: '',

      tabs: [
        {
          name: 'choice',
          label: '首页精选',
        },
        {
          name: 'more',
          label: '更多作品',
        },
      ],
      activeName: 'choice',

      labels: [],
      activeLabel: '',

      caseList: [],
      page: 1,
      hasData: true,

      loading: true,
    }
  },
  created() {
    this.navId = this.$route.query.navId
  },
  mounted() {
    this.getIndexLabel()
    this.getCaseList({ type: 'refresh' })
  },
  methods: {
    onTabClick(index) {
      this.loading = true
      this.activeName = this.tabs[index].name
      this.activeLabel = ''
      this.page = 1
      this.hasData = true
      this.getCaseList({ type: 'refresh' })
    },
    onLabelClick(index) {
      this.loading = true
      this.activeLabel = this.labels[index].label_id
      this.page = 1
      this.hasData = true
      this.getCaseList({ type: 'refresh' })
    },
    showMore() {
      if (this.hasData) {
        this.loading = true
        this.page++
        this.getCaseList({ type: 'more' })
      } else {
        this.$message('没有更多数据了')
      }
    },
    async getIndexLabel() {
      let res = await indexLabel()
      this.labels = res.data.labels
    },
    async getCaseList({ type = 'refresh' }) {
      try {
        let res = await caseList({
          category_id: this.navId,
          page: this.page,
          label_id: this.activeLabel,
        })
        setTimeout(() => {
          this.loading = false
        }, 400)
        let list = res.data.list
        if (list && list.length > 0) {
          if (type == 'refresh') {
            this.caseList = [...list]
          } else if (type == 'more') {
            this.caseList = [...this.caseList, ...list]
          }
        } else {
          this.hasData = false
          this.$message('没有更多数据了')
        }
      } catch (error) {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../styles/var.scss';
.home {
  .banner {
    width: 100%;
    overflow: hidden;
    img {
      transition: all 0.5s;
    }
    img:hover {
      transform: scale(1.05);
    }
  }
  .tabs {
    width: 100%;

    .header {
      display: flex;
      justify-content: center;
      padding: 20px 0 10px;
      box-shadow: 4px 0px 4px 0px rgba(131, 131, 131, 0.47);
      ul {
        display: flex;
        li {
          font-size: 16px;
          font-weight: 500;
          color: #959595;
          padding: 0 16px;
          position: relative;
          cursor: pointer;
          &.active {
            color: $saffron;
            &::after {
              display: block;
              content: '';
              position: absolute;
              left: 50%;
              bottom: -5px;
              transform: translateX(-50%);
              width: 43px;
              height: 3px;
              background-color: $saffron;
              border-radius: 2px;
            }
          }
        }
      }
    }
    .content {
      background-color: #f4f5f9;
      .caseBox {
        box-sizing: border-box;
        width: 1340px;
        display: flex;
        flex-wrap: wrap;
        padding: 35px 0 15px 0;
        margin: 0 auto;
        .case {
          // width: 350px;
          margin: 0 5px 10px;
          background: #fff;
          padding: 5px;
        }
      }
    }

    .labels {
      display: flex;
      justify-content: center;
      background-color: #f4f5f9;
      .label {
        cursor: pointer;
        padding: 10px 16px 0;
        span {
          display: inline-block;
          font-size: 14px;
          line-height: 1;
          color: #828282;
          padding: 5px 7px;

          &.active {
            color: #1a1a1a;
            background-color: $saffron;
            border-radius: 8px;
          }
        }
      }
    }
  }
  .showMore {
    display: flex;
    justify-content: center;
    padding-bottom: 48px;
    background-color: #f4f5f9;
    .btn {
      background-color: $saffron;
      font-size: 18px;
      font-weight: bold;
      color: #444446;
      padding: 14px 24px;
      cursor: pointer;
    }
    .noMore {
      .noMoreText {
        display: inline-block;
        font-size: 18px;
        color: #000;
        position: relative;
        &::before,
        &::after {
          display: block;
          content: '';
          position: absolute;
          width: 60px;
          height: 2px;
          background-color: #444445;
          top: 50%;
          transform: translateY(-50%);
        }
        &::before {
          left: -65px;
        }
        &::after {
          right: -65px;
        }
      }
    }
  }
}
</style>

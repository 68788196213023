<template>
  <div class="mCase" @click="onClickCase()">
    <img class="image" :src="item.page" />
    <div class="bottom">
      <div class="line lineOne">
        <div class="name ellipsis">{{ item.works_name }}</div>
        <div class="collect" @click="collect" v-if="false">
          <img class="icon" src="../../images/heart.png" alt="爱心" />
          <span class="text">收藏</span>
        </div>
      </div>
      <div class="line lineTwo">
        <div class="label ellipsis">标签：{{ item.label_name }}</div>
        <div class="time">{{ item.create_time | timeFilter }}</div>
      </div>
    </div>
    <!-- <div class="type">
      <div class="typeIcon center">
        <img src="../../images/caseIocn/pc.png" alt="" />
      </div>
      <div class="typeString">电脑端网站</div>
    </div> -->
  </div>
</template>

<script>
import { timeFormat } from '@/utils/index'
export default {
  props: ['item'],
  data() {
    return {}
  },
  methods: {
    collect() {},
    onClickCase() {
      this.$router.push(`/caseDetail/${this.item.works_id}`)
    },
  },
  filters: {
    timeFilter(time) {
      return timeFormat(time * 1000, 'YYYY/M/D')
    },
  },
}
</script>

<style lang="scss">
.mCase {
  .type {
    position: absolute;
    left: 15px;
    top: 244px;
    color: #fff;
    font-size: 16px;
  }
  .typeIcon {
    width: 40px;
    height: 40px;
    background: #fff;
    margin: 0 auto;
    border-radius: 50%;
    box-shadow: 2px 8px 12px 0px rgba(131, 131, 131, 0.14);
    img {
      width: 25px;
      height: 25px;
    }
  }
  .typeString {
    padding-top: 5px;
  }
  // width: 350px;
  position: relative;
  background-color: #fff;

  transition: all 0.3s;
  cursor: pointer;
  .image {
    width: 313px;
    height: 313px;
    margin: 0 auto;
    object-fit: cover;
  }
  .bottom {
    padding: 10px 5px;
    max-width: 313px;
    margin: 0 auto;
    .line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 1;
      padding: 5px 0;
    }
    .lineOne {
      .name {
        font-size: 16px;
        font-weight: 700;
        color: #383838;
        width: 100%;
        text-align: left;
      }
      .collect {
        display: flex;
        align-items: center;
        cursor: pointer;
        .icon {
          margin-right: 5px;
        }
        .text {
          font-size: 12px;
          font-weight: 300;
          color: #828282;
        }
      }
    }
    .lineTwo {
      font-size: 12px;
      font-weight: 300;
      color: #828282;
      .label {
        max-width: 200px;
      }
    }
  }
  &:hover {
    transform: scale(1.02);
    box-shadow: 1px 8px 12px 0px rgba(131, 131, 131, 0.14);
  }
}
</style>
